import { createStore } from 'vuex'

const store = createStore({
  strict: process.env.NODE_ENV !== 'development',
  plugins: [
  ],
  state: {
  },
  mutations: {
  },
  actions: {
  },
  getters: {
  },
  modules: {
  }
})

export default store