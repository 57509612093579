import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store/index'

const app = createApp(App)
const VueScrollTo = require('vue-scrollto')
app.use(VueScrollTo)
app.use(store)
app.use(router)
app.config.productionTip = false
router.isReady().then(() => {
  app.mount('#app')
})
