<template>
  <div id="app">
    <router-view/>
        <snack-bar />
  </div>
</template>


<script>
import SnackBar from "./components/SnackBar.vue";
import { ref } from "vue";
export default {
  name: "Ace",
  components: {
    SnackBar
  },
  setup() {
    const snackbar = ref(false);

    return {
      snackbar
    };
  },
};
</script>
<style lang="css">

html, body {
  margin: 0;
  padding: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}
</style>
