import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
    history: createWebHistory(),

    routes: [
  {
    path: '/',
    named: '',
    component: () => import(/* webpackChunkName: "Home" */ '../views/Home.vue'),
    meta: {
      title: 'Ace Sewing Machine Service | Home'
    }
  }
]
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  next()
})

export default router
